import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import ProfiledetailsHeader from "./ProfiledetailsHeader";
import Layout from "../components/Layout/Layout";

export default function ContactSupport() {
  return (
    <Layout type="details">
      <div className="mydetails">
        {/* Header Start */}

        <div className="tabs_content">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="you_know">
                  <h2>Did you know?</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi ac dolor viverra, varius massa sit ame. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Morbi ac dolor
                    viverra, varius massa sit ame. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Morbi ac dolor viverra, varius
                    massa sit ame. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Morbi ac dolor viverra, varius massa sit
                    ame. Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Morbi ac dolor viverra, varius massa sit ame.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="account_form contact_support">
                  <form>
                    <div>
                      <h2>Contact Support</h2>
                      <div className="row">
                        <div className="col-lg-12">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Type of Query</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="exampleFormControlTextarea1">
                            Type comments here...
                          </label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="submit-btn">
                      <button type="submit" class="btn mb-3">
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
