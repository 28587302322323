import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ProfiledetailsHeader from "./ProfiledetailsHeader";
import Layout from "../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { customerdetailsAction, updateProfileDetailAction } from "../Redux/Action/CustomerAuthAction";
import toast from "react-hot-toast";

export default function ContactPreferences() {

  const dispatch = useDispatch();
  const [validateValue, setValidateValue] = useState(false);

  const [customerdetails, setCustomerdetails] = useState();

  useEffect(() => {
    dispatch(customerdetailsAction()).then(function (data) {
      setCustomerdetails(data.payload);

      formik.setFieldValue("contact_pref_notify", data?.payload?.contact_pref_notify);
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      contact_pref_notify: customerdetails?.contact_pref_notify || false,
    },
    validateOnChange: validateValue,
    onSubmit: (values) => {
      setValidateValue(true)
      console.log(values.contact_pref_notify, "contact_pref_notify")
      dispatch(updateProfileDetailAction(values)).then((data) => {
        if (data?.payload?.success) {
          //resetForm();
          toast.success(data?.payload?.message)
        }
      });

    },
  });

  console.log(customerdetails, "customerdetails");
  return (
    <Layout type="details">
      <div className="Booking_main">
        {/* Header Start */}

        <div className="tabs_content">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="you_know">
                  <h2>Did you know?</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi ac dolor viverra, varius massa sit ame. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Morbi ac dolor
                    viverra, varius massa sit ame. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Morbi ac dolor viverra, varius
                    massa sit ame. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Morbi ac dolor viverra, varius massa sit
                    ame. Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Morbi ac dolor viverra, varius massa sit ame.{" "}
                  </p>
                </div>
              </div>
              
              <div className="col-lg-7">
              <form onSubmit={formik.handleSubmit}>
                <div className="contact_prefer">
                  <div>
                    <h2>Contact Preferences</h2>
                    <ul className="yes_no_main">
                      <li>
                        <p>Yes</p>
                      </li>
                      <li>
                        <p>No</p>
                      </li>
                    </ul>
                    <ul>

                      <li>
                        {" "}
                        <p>
                          I want to receive notifications for new offers and
                          promotions{" "}
                        </p>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="contact_pref_notify"
                            id="flexRadioDefault1"
                            checked={formik.values.contact_pref_notify ? true : false}
                            value={formik.values.contact_pref_notify}
                            onChange={() =>
                              formik.setFieldValue("contact_pref_notify", true)
                            }
                          />
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="contact_pref_notify"
                            id="flexRadioDefault2"
                            checked={formik.values.contact_pref_notify == false ? true : false}
                            value={formik.values.contact_pref_notify}
                            onChange={() =>
                              formik.setFieldValue("contact_pref_notify", false)
                            }
                          />
                        </div>
                      </li>

                    </ul>
                  </div>

                  <div className="submit-btn">
                    <button type="submit" class="btn mb-3">Update</button>
                  </div>
                </div>
                </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
