import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { fetchAllAppointments } from "../Redux/Action/CustomerRestAction";
import { Encryptedid } from "../utils/BcruptEncyptid";
import toast from "react-hot-toast";

const Schedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState();

  const { appointments } = useSelector((state) => ({
    appointments: state.customer.appointments,
  }));

  useEffect(() => {
    dispatch(fetchAllAppointments()).then((data) => {
      setFilter(data?.payload?.appointments);
    });
  }, []);

  console.log(filter, "filter");

  const handleEventClick = (arg) => {
    const appointment = arg.event._def.extendedProps;
    console.log(appointment,"appointment");
    if(appointment?.appointmentId?.appointment_type == 0){
      navigate(`/video_consultation/${Encryptedid(appointment?.appointmentId?._id)}`)
    }else if(appointment?.appointmentId?.appointment_type == 1){
      toast.success('Salon Visit')
    }
  };

  const handleFilter = (e) => {
    const arr = appointments?.filter(
      (item) => item?.appointment_type == e.target.value
    );
    setFilter(arr);
  };

  return (
    <Layout>
      <section className="schedule-calendor">
        <div className="container">
          <div className="schedule_main">
            <div className="sch_heading">
              <h2>Calendar</h2>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="select_box d-flex">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={handleFilter}
                  >
                    <option selected>Booking Type</option>
                    <option value="0">Video Consultation</option>
                    <option value="1">Salon Visit</option>
                  </select>
                </div>
                <div className="schedule_details">
                  <ul>
                    {filter?.map((item, i) => {
                      return (
                        <li>
                          <h3>
                            {item?.salonId?.firstname +
                              " " +
                              item?.salonId?.lastname}
                          </h3>
                          <h4>{item?.description}</h4>
                          <div>
                            {item?.appointment_type == 0
                              ? "Video Consultation"
                              : "Salon Visit"}
                            <p>
                              {moment(item?.date).format("DD/MM/YYYY")}{" "}
                              {item?.time}
                            </p>
                          </div>
                          <div className="right-btns-schedule">
                            <button type="button">
                              <img
                                src={
                                  require("../assets/img/Book_check.svg")
                                    .default
                                }
                              />
                            </button>
                            <button type="button">
                              <img
                                src={
                                  require("../assets/img/Close_round_duotone.svg")
                                    .default
                                }
                              />
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  headerToolbar={{
                    left: "",
                    center: "title",
                    // right: "dayGridMonth,timeGridWeek,timeGridDay",
                    right: "prev,next today",
                  }}
                  initialView="dayGridMonth"
                  events={filter?.map((item) => {
                    return {
                      date: `${item?.date?.split("T")[0]}T${item?.time}`,
                      title: item?.salonId?.salonname,
                      appointmentId:item
                    };
                  })}
                  eventClick={handleEventClick}
                  eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Schedule;
