import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Layout from "../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  customerDashboardDetails,
  fetchAllAppointments,
} from "../Redux/Action/CustomerRestAction";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import { Encryptedid } from "../utils/BcruptEncyptid";

const Clientview = () => {
  const dispatch = useDispatch();

  const {
    upcomingSchedule,
    appointmentHistory,
    customerDetail,
    upcomingSchedule2,
    appointmentHistory2,
  } = useSelector((state) => ({
    upcomingSchedule: state.customer.userInfo?.upcomingSchedule,
    appointmentHistory: state.customer.userInfo?.appointmentHistory,
    customerDetail: state.customer.userInfo?.customerDetail,
    //APPOINTMENTS
    upcomingSchedule2: state.customer?.upcomingSchedule,
    appointmentHistory2: state.customer?.appointmentHistory,
  }));

  useEffect(() => {
    dispatch(customerDashboardDetails());
    dispatch(fetchAllAppointments());
  }, []);

  console.log(customerDetail, "customerDetail");

  return (
    <Layout>
      <section className="product_view">
        <div className="container custom">
          <div className="productmain_wrap">
            <div className="row">
              {/* <div className="col-lg-12">
                                <div className="prev_main">
                                    <img src={require('../../src/assets/img/right-arrow.svg').default} alt='arrow' className="arrow-next-green" />
                                    <a class="prev_result" href="#">
                                        Back to search results
                                    </a>
                                </div>
                            </div> */}
              <div className="col-lg-6">
                <div className="user_info bod_cmn">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img
                        src={require("../assets/img/logo-circle.png")}
                        alt="arrow"
                        className="logo-main"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <h2 className="client_cmn_heading">
                        {customerDetail?.first_name +
                          " " +
                          customerDetail?.last_name}
                      </h2>

                      <p> {customerDetail?.first_line_address}</p>
                      <p>{customerDetail?.second_line_address}</p>

                      <p>{customerDetail?.city}</p>

                      <p>{customerDetail?.pin_code}</p>
                      <br />
                      <p>{customerDetail?.email}</p>
                      <br />
                      {customerDetail?.phone_number && (
                        <p>{`+44 ` + customerDetail?.phone_number}</p>
                      )}

                      {/* <p>Age: 36   (23/07/1986)</p> */}
                    </div>
                  </div>
                </div>

                {/* <div className="tests bod_cmn">
                                    <h2 className="client_cmn_heading">Active Patch Tests</h2>
                                    <div class="table-responsive">
                                        <table>
                                            <tr>
                                                <th className="pl-5">Treatment Type</th>
                                                <th className="text-left">Expiry</th>
                                                <th></th>
                                            </tr> */}
                {/* <tr>
                                                <td className="text-left">Eyebrow Tinting</td>
                                                <td className="text-left">01/02/2024</td>
                                                <td><Nav.Link href="#">View</Nav.Link></td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">Eyebrow Tinting</td>
                                                <td className="text-left">Expired</td>
                                                <td><Nav.Link href="#">View</Nav.Link></td>
                                            </tr> */}
                {/* </table>
                                    </div>
                                </div> */}

                <div className="invoices bod_cmn">
                  <h2 className="client_cmn_heading">Invoices</h2>
                  <div class="table-responsive">
                    <table>
                      <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                      {/* <tr>
                                                <td className="text-left">10/08/2023</td>
                                                <td className="text-left">Booking Deposit</td>
                                                <td className="text-left"><Nav.Link href="#" className="d-cont">£15.00</Nav.Link></td>
                                              
                                                <td><img src={require('../assets/img/Done_duotone.png')} alt='arrow' /></td>
                                            </tr>
                                            <tr>
                                                <td className="text-left">21/09/2023</td>
                                                <td className="text-left">No Show Fee</td>
                                                <td className="text-left"><Nav.Link href="#" className="d-cont">£55.00</Nav.Link></td>
                                                <td><img src={require('../assets/img/Done_duotone.png')} alt='arrow' /></td>
                                            </tr> */}
                    </table>
                    <p className="no-information">No information to show</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="schedule bod_cmn">
                  <div className="schedule_top">
                    <h2 className="client_cmn_heading">Upcoming Schedule</h2>
                  </div>
                  <div class="table-responsive">
                    <table>
                      {upcomingSchedule?.length > 0 ||
                      upcomingSchedule2?.length > 0 ? (
                        <>
                          {upcomingSchedule?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td className="text-left">
                                    {item?.consultationId?.form_title || "N/A"}
                                  </td>
                                  <td>Online Consultation</td>
                                  <td>
                                    <NavLink
                                      to={`/consultation_form/${Encryptedid(
                                        item?.consultationId?._id
                                      )}/${Encryptedid(item?._id)}`}
                                    >
                                      View
                                    </NavLink>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          {upcomingSchedule2?.map((item, i) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    {moment(item?.date).format("DD/MM/YYYY")}{" "}
                                    {item?.time}
                                  </td>
                                  <td className="text-left">
                                    {item?.description}
                                  </td>
                                  <td>
                                    {item?.appointment_type == 0
                                      ? "Video Consultation"
                                      : "Salon Visit"}
                                  </td>
                                  <td>
                                    {item?.appointment_type == 0 ? (
                                      <Nav.Link
                                        as={Link}
                                        to={`/video_consultation/${Encryptedid(
                                          item?._id
                                        )}`}
                                      >
                                        View
                                      </Nav.Link>
                                    ) : (
                                      "View"
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <p className="no-information">No information to show</p>
                      )}
                    </table>
                  </div>
                </div>

                <div className="appointment bod_cmn">
                  <div className="schedule_top">
                    <h2 className="client_cmn_heading">Appointment History</h2>
                  </div>
                  <div class="table-responsive">
                    <table>
                      {appointmentHistory?.length > 0 ||
                      appointmentHistory2?.length > 0 ? (
                        <>
                          {appointmentHistory?.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td className="text-left">
                                    {item?.consultationId?.form_title || "N/A"}
                                  </td>
                                  <td>Online Consultation</td>
                                  <td>
                                    <NavLink
                                      to={`/view_consultation_form/${Encryptedid(
                                        item?._id
                                      )}`}
                                    >
                                      View
                                    </NavLink>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                          {appointmentHistory2?.map((item, i) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    {moment(item?.date).format("DD/MM/YYYY")}{" "}
                                    {item?.time}
                                  </td>
                                  <td className="text-left">
                                    {item?.description}
                                  </td>
                                  <td>
                                    {item?.appointment_type == 0
                                      ? "Video Consultation"
                                      : "Salon Visit"}
                                  </td>
                                  <td>
                                    {item?.appointment_type == 0 ? (
                                      <Nav.Link
                                        as={Link}
                                        to={`/video_consultation/${Encryptedid(
                                          item?._id
                                        )}`}
                                      >
                                        View
                                      </Nav.Link>
                                    ) : (
                                      "View"
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <p className="no-information">No information to show</p>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Clientview;
