import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contactpreferences from "./Pages/ContactPreferences";
import Contactsecurity from "./Pages/ContactSupport";
import Invoices from "./Pages/Invoice";
import Mydetails from "./Pages/Mydetails";
import Passsecurity from "./Pages/PasswordSecurity";
import Salonview from "./Pages/Salon-profile";
import Salonsearch from "./Pages/SalonSearch";
import SignUp from "./Pages/SignUp";
import Startconsultation from "./Pages/start-consultation ";
import Clientview from "./Pages/Client_view";
import Completedconsultation from "./Pages/Completed_consultation";
import Connectpatch from "./Pages/Connect-Patchport";
import Invoicesalon from "./Pages/Invoice-Salon";
import Schedule from "./Pages/Schedule";
import Salonmodal from "./Pages/Salon-modal";
import Signin from "./Pages/Signin";
import { Toaster } from "react-hot-toast";
import ResetPassword from "./Pages/ResetPassword";
import Passwordreset from "./Pages/Passwordreset";
import EmailVerification from "./Pages/EmailVerification";
import ViewConsultationForm from "./Pages/ViewConsultationForm";
import ConsultationForm from "./Pages/ConsultationForm";
import VideoConsultation from "./Pages/VideoConsultation";

function App() {
  return (
    <div className="App">
      <Toaster position="top-center" reverseOrder={false} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/:id/:time" element={<Passwordreset />} />

          <Route path="/SalonSearch" element={<Salonsearch />} />

          <Route path="/Contact-preferences" element={<Contactpreferences />} />
          <Route path="/Contact-support" element={<Contactsecurity />} />
          <Route path="/Invoice" element={<Invoices />} />
          <Route path="/Mydetails" element={<Mydetails />} />
          <Route path="/Password-security" element={<Passsecurity />} />
          <Route path="/Salon-profile" element={<Salonview />} />
          <Route path="/start-consultation" element={<Startconsultation />} />
          <Route path="/video_consultation/:id" element={<VideoConsultation />} />
          <Route path="/Client_view" element={<Clientview />} />
          <Route
            path="/Completed_consultation"
            element={<Completedconsultation />}
          />
          <Route path="/Connect-Patchport" element={<Connectpatch />} />
          <Route path="/Invoice-Salon" element={<Invoicesalon />} />
          <Route path="/Schedule" element={<Schedule />} />
          <Route path="/Salon-modal" element={<Salonmodal />} />
          <Route path="/My-subscription/:id" element={<EmailVerification />} />

          <Route
            path="/view_consultation_form/:id"
            element={<ViewConsultationForm />}
          />
          <Route
            path="/consultation_form/:id/:formId"
            element={<ConsultationForm />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
