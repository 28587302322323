import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import ProfiledetailsHeader from "./ProfiledetailsHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  customerdetailsAction,
  updateProfileDetailAction,
} from "../Redux/Action/CustomerAuthAction";
import Layout from "../components/Layout/Layout";

export default function Mydetails() {
  const dispatch = useDispatch();
  const [customerdetails, setCustomerdetails] = useState("");

  useEffect(() => {
    dispatch(customerdetailsAction()).then(function (data) {
      setCustomerdetails(data.payload);

      formik.setFieldValue("first_name", data?.payload?.first_name);
      formik.setFieldValue("last_name", data.payload?.last_name);
      formik.setFieldValue("email", data.payload?.email);
      formik.setFieldValue("phone_number", data.payload?.phone_number);
      formik.setFieldValue(
        "first_line_address",
        data.payload?.first_line_address
      );
      formik.setFieldValue(
        "second_line_address",
        data.payload?.second_line_address
      );
      formik.setFieldValue("city", data.payload?.city);
      formik.setFieldValue("pin_code", data.payload?.pin_code);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: customerdetails?.first_name,
      last_name: customerdetails?.last_name,
      email: customerdetails?.email,
      phone_number: customerdetails?.phone_number,
      first_line_address: customerdetails?.first_line_address,
      second_line_address: customerdetails?.second_line_address,
      city: customerdetails?.city,
      pin_code: customerdetails?.pin_code,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string().email().required("Email is required"),
      phone_number: Yup.string().required("Phone Number is required"),
      first_line_address: Yup.string().required(
        "First line Address is required"
      ),
      city: Yup.string().required("City is required"),
      pin_code: Yup.string().required("Pin code is required"),
    }),
    onSubmit: (values) => {
      dispatch(updateProfileDetailAction(values));
      // Handle step 1 submission
    },
  });

  return (
    <Layout type="details">
      <div className="mydetails">
        <div className="tabs_content">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="you_know">
                  <h2>Did you know?</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi ac dolor viverra, varius massa sit ame. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Morbi ac dolor
                    viverra, varius massa sit ame. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Morbi ac dolor viverra, varius
                    massa sit ame. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Morbi ac dolor viverra, varius massa sit
                    ame. Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Morbi ac dolor viverra, varius massa sit ame.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="account_form">
                  <form onSubmit={formik.handleSubmit}>
                    <h2>My Details</h2>
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          placeholder="First Name *"
                          name="first_name"
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.first_name &&
                          formik.errors.first_name && (
                            <div className="error">
                              {formik.errors.first_name}
                            </div>
                          )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          placeholder="Surname *"
                          name="last_name"
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.last_name &&
                          formik.errors.last_name && (
                            <div className="error">
                              {formik.errors.last_name}
                            </div>
                          )}
                      </div>
                 
                      <div className="col-lg-12">
                        <input
                          className="form-control"
                          placeholder="Email *"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="error">{formik.errors.email}</div>
                        )}
                      </div>

                      <div className="col-lg-12">
                        <input
                          className="form-control"
                          placeholder="Phone Number *"
                          name="phone_number"
                          value={formik.values.phone_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone_number &&
                          formik.errors.phone_number && (
                            <div className="error">
                              {formik.errors.phone_number}
                            </div>
                          )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          placeholder="First Line of Address"
                          name="first_line_address"
                          value={formik.values?.first_line_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.first_line_address &&
                          formik.errors.first_line_address && (
                            <div className="error">
                              {formik.errors.first_line_address}
                            </div>
                          )}
                      </div>

                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          placeholder="Second Line of Address "
                          name="second_line_address"
                          value={formik.values.second_line_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>

                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          placeholder="City"
                          name="city"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.city && formik.errors.city && (
                          <div className="error">{formik.errors.city}</div>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          className="form-control"
                          placeholder="Post Code"
                          name="pin_code"
                          value={formik.values.pin_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.pin_code && formik.errors.pin_code && (
                          <div className="error">{formik.errors.pin_code}</div>
                        )}
                      </div>
                      <div className="submit-btn">
                        <button type="submit" class="btn mb-3">
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
